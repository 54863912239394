import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {ProductActions} from '@wix/wixstores-client-storefront-sdk/dist/es/src/product-actions/ProductActions';
import {PageMap, AddToCartActionOption, STORAGE_PAGINATION_KEY} from '@wix/wixstores-client-core/dist/es/src/constants';
import {IProductWidgetDTO} from '../types/app-types';
import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types';
import {FedopsLogger} from '@wix/fedops-logger';
import {IStoreFrontNavigationContext} from '@wix/wixstores-client-core/dist/es/src/types/site-map';
import {origin} from '../constants';
import {
  actualPrice,
  actualSku,
  hasSubscriptionPlans,
} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';

export class ProductService {
  private readonly productActions: ProductActions;

  constructor(
    private readonly siteStore: SiteStore,
    private readonly compId: IWidgetControllerConfig['compId'],
    private readonly externalId: string,
    private readonly fedopsLogger: FedopsLogger
  ) {
    this.productActions = new ProductActions(this.siteStore);
  }

  private hasSubscriptions(product: IProductWidgetDTO) {
    return hasSubscriptionPlans(product);
  }

  private storeNavigationHistory() {
    const pageId = this.siteStore.siteApis.currentPage.id;
    const history: IStoreFrontNavigationContext = {
      pageId,
      paginationMap: [],
    };
    this.siteStore.storage.local.setItem(STORAGE_PAGINATION_KEY, JSON.stringify(history));
  }

  private trackParams(product: IProductWidgetDTO) {
    return {
      id: product.id,
      name: product.name,
      price: actualPrice(product),
      sku: actualSku(product),
      type: product.productType,
    };
  }

  public handleClick(product: IProductWidgetDTO, navigate: boolean): Promise<any> {
    const customTextFields = undefined;
    const selectionIds = undefined;
    const quantity = 1;

    if (navigate) {
      this.storeNavigationHistory();

      return this.siteStore.navigate(
        {
          sectionId: PageMap.PRODUCT,
          state: product.urlPart,
          queryParams: undefined,
        },
        true
      );
    } else if (!product.hasOptions && !this.hasSubscriptions(product)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.fedopsLogger.interactionStarted('add-to-cart');
      return this.siteStore.cartActions.addToCart(
        product.id,
        selectionIds,
        quantity,
        customTextFields,
        AddToCartActionOption.MINI_CART,
        origin,
        () => this.fedopsLogger.interactionEnded('add-to-cart'),
        this.trackParams(product)
      );
    } else {
      return this.productActions.quickViewProduct({
        origin: 'productwidget',
        urlPart: product.urlPart,
        compId: this.compId,
        externalId: this.externalId,
      });
    }
  }
}
